

























































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DateTimePicker from "../../components/ui/DateTime-Picker.vue";

@Component({
  components: {
    DateTimePicker,
  },
})
export default class MaintenanceCompletedModal extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */
  
  @Prop() basicDetailData!: any;
  @Prop() showModal = false;
  @Prop() onHideModal!: Function;
  @Prop() onSubmitModal!: Function;

  // Variables
  completion_date = "";
  completion_dateError = "";

  costing: any = 0;
  costingError = "";

  spared_replaced = "";
  spared_replacedError = "";

  broken_spared_returned = "yes";
  broken_spared_returnedError = "";

  consumable_spare_cost: any = 0;
  consumable_spare_costError = "";

  replaced_spare_cost: any = 0;
  replaced_spare_costError = "";

  async submitData() {
    const response = await fetch(
      `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/${this.basicDetailData.id}`,
      {
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cost_whole: `${this.costing}`,
          // cost_decimal: this.costing,
          completion_date: this.completion_date,
          spared_replaced: this.spared_replaced,
          broken_spared_returned: this.broken_spared_returned,
          replaced_spare_cost: this.replaced_spare_cost,
        }),
      }
    );

    if (response.status == 200) {
      this.$store.commit("showToastMessage", {
        boldText: "Success!",
        text: "Maintenance completed successfully",
        type: "Success",
      });
      this.$router.replace({ path: "/asset-tracker/maintenance" });
    } else if (response.status == 400) {
      const error = await response.json();
      switch (error[0].path[0]) {
        case "completion_date":
          this.completion_dateError = error[0].message;
          break;
        case "cost_whole":
          this.costingError = error[0].message;
          break;
        case "cost_decimal":
          this.costingError = error[0].message;
          break;
        case "spared_replaced":
          this.spared_replacedError = error[0].message;
          break;
        case "broken_spared_returned":
          this.broken_spared_returnedError = error[0].message;
          break;
        case "consumable_spare_cost":
          this.consumable_spare_costError = error[0].message;
          break;
        case "replaced_spare_cost":
          this.replaced_spare_costError = error[0].message;
          break;
        default:
          break;
      }
    }
    await this.onSubmitModal();
  }

  onCompletionDateChanged(e: any) {
    this.completion_date = e;
    this.completion_dateError = "";
  }

  @Watch("consumable_spare_cost") onwatchconsumable_spare_cost() {
    this.consumable_spare_costError = "";
    this.costing =
      Number(this.consumable_spare_cost) + Number(this.replaced_spare_cost);
  }
  @Watch("replaced_spare_cost") onwatchreplaced_spare_cost() {
    this.replaced_spare_costError = "";
    this.costing =
      Number(this.consumable_spare_cost) + Number(this.replaced_spare_cost);
  }
}
