




















































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  CheckSquareIcon,
  EditIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckCircleIcon,
  DownloadIcon
} from "vue-feather-icons";
import Loader from "../components/ui/Loader.vue";
// import Modal from "../components/ui/Modal.vue";
import NoData from "../components/ui/No-Data.vue";
import DaterangePicker from "../components/ui/Daterange-Picker.vue";
import MaintenanceCompletedModal from "../components/maintenance-detail/Maintenance-CompletedModal.vue";
import moment from "moment";

import XLSX from "xlsx";

@Component({
  components: {
    CheckSquareIcon,
    EditIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    Loader,
    // Modal,
    NoData,
    DaterangePicker,
    CheckCircleIcon,
    MaintenanceCompletedModal,
    DownloadIcon
  },
})
export default class Maintenance extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  assetsTotalPages = 1;
  assetsCurrentPage = 1;
  assetsStartIndex = 0;
  assetsEndIndex = 0;
  assetsLength = 0;
  assetsList: any = [];
  isLoading = true;
  showDeleteModal = false;
  showNoData = false;
  noDataType = "";

  showMaintenanceCompletedModal = false;
  selectedMaintenanceCompletedData: any = "";
  onshowMaintenanceCompletedModal(index: number) {
    this.selectedMaintenanceCompletedData = { ...this.assetsList[index] };

    if (this.selectedMaintenanceCompletedData.status == "completed") {
      this.$store.commit("showToastMessage", {
        boldText: "Hey!",
        text: "Maintenance already completed",
        type: "Success",
      });
    } else {
      this.idToBeUpdated = this.assetsList[index].id;
      setTimeout(() => {
        this.showMaintenanceCompletedModal = true;
      }, 10);
    }
  }

  onHideMaintenanceCompletedModal() {
    this.showMaintenanceCompletedModal = false;
  }

  async onSubmitMaintenanceCompletedModal() {
    this.onHideMaintenanceCompletedModal();
    this.onReloadData();
  }

  mounted() {
    setTimeout(() => {
      this.fetchMaintenanceList("", "", false);
    }, 0);
  }

  lastQueryStartDate = "";
  lastQueryEndDate = "";

  onDateRangeChange(dateRange) {
    this.lastQueryStartDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    this.lastQueryEndDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    this.fetchMaintenanceList(
      moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD"),
      false
    );
  }

  filterType = "scheduled";
  @Watch("filterType")
  onwatchfilterType() {
    if (this.lastQueryStartDate && this.lastQueryEndDate) {
      this.fetchMaintenanceList(
        this.lastQueryStartDate,
        this.lastQueryEndDate,
        false
      );
    }
  }

  onReloadData() {
    setTimeout(() => {
      this.fetchMaintenanceList("", "", false);
    }, 0);
  }

  onGenerateReport() {
    if (this.assetsList.length > 0) {
      const data = XLSX.utils.json_to_sheet(
        this.assetsList.map((x: any) => ({
          asset: x.asset ? x.asset.brand + " " + x.asset.model : "- -",
          title: x.title,
          description: x.description,
          scheduled_date: x.scheduled_date,
          completion_date: x.completion_date,
          status: x.status,
          type: x.type,
          created_on: x.created_on,
        }))
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "maintenance.xlsx");
    } else {
      this.$store.commit("showToastMessage", {
        boldText: "Failed!",
        text: "No Maintenance found",
        type: "Danger",
      });
    }
  }

  async onGenerateMISReport() {
    await fetch(`${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/pdf`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download =
          "maintenance-report-" +
          new Date(Date.now()).toLocaleDateString().replaceAll("/", "-") +
          ".pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  async fetchMaintenanceList(startDate, endDate, generateReport) {
    try {
      this.showNoData = false;
      this.isLoading = true;

      let url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance`;

      if (startDate && endDate) {
        if (startDate == endDate) {
          if (this.filterType == "scheduled") {
            url += `?fixedScheduledDate=${startDate}`;
          } else {
            url += `?fixedCompletionDate=${startDate}`;
          }
        } else {
          if (this.filterType == "scheduled") {
            url += `?scheduledStartDate=${startDate}&scheduledEndDate=${endDate}`;
          } else {
            url += `?completionStartDate=${startDate}&completionEndDate=${endDate}`;
          }
        }
        if (generateReport) {
          url += `&excel=true`;
        }
      } else {
        if (generateReport) {
          url += `?excel=true`;
        }
      }

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (response.status == 200) {
        this.assetsList = await response.json();
        if (this.assetsList) {
          this.assetsList = [...this.assetsList.reverse()];
        }
        this.assetsLength = this.assetsList.length;
        this.initPaginations(this.assetsLength);

        if (this.assetsList.length === 0) {
          this.noDataType = "Empty";
          this.showNoData = true;
        }
      } else {
        this.noDataType = "Error";
        this.showNoData = true;
      }
    } catch (e) {
      this.noDataType = "Error";
      this.showNoData = true;
    } finally {
      this.isLoading = false;
    }
  }

  initPaginations(length: number) {
    this.assetsEndIndex = length > 5 ? 5 : length;
    this.assetsTotalPages =
      this.assetsList.length % 5 === 0
        ? Math.floor(this.assetsList.length / 5)
        : Math.floor(this.assetsList.length / 5 + 1);
  }

  onNextPagination() {
    if (this.assetsCurrentPage >= this.assetsTotalPages) return;

    this.assetsCurrentPage += 1;
    this.assetsStartIndex = this.assetsEndIndex;
    this.assetsEndIndex =
      this.assetsList.length > this.assetsStartIndex + 5
        ? this.assetsStartIndex + 5
        : this.assetsList.length;
  }

  onPrevPagination() {
    if (this.assetsCurrentPage <= 1) return;

    this.assetsCurrentPage -= 1;
    this.assetsEndIndex = this.assetsStartIndex;
    this.assetsStartIndex =
      this.assetsStartIndex - 5 <= 0 ? 0 : this.assetsStartIndex - 5;
  }

  // onShowDeleteModal(id: string) {
  //   this.showDeleteModal = true;
  //   this.idToBeDeleted = id;
  // }

  // idToBeDeleted = "";
  // async onDeleteMaintenance() {
  //   if (this.idToBeDeleted !== "") {
  //     try {
  //       const response = await fetch(
  //         `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/${this.idToBeDeleted}`,
  //         {
  //           method: "DELETE",
  //           credentials: "include",
  //         }
  //       );

  //       if (response.status === 200) {
  //         this.$store.commit("showToastMessage", {
  //           boldText: "Successfully!",
  //           text: "Maintenance deleted",
  //           type: "Success",
  //         });
  //         this.onReloadData();
  //       } else {
  //         this.$store.commit("showToastMessage", {
  //           boldText: "Failed!",
  //           text: "Something went wrong",
  //           type: "Danger",
  //         });
  //       }
  //     } catch (e) {
  //       this.$store.commit("showToastMessage", {
  //         boldText: "Error!",
  //         text: e.message,
  //         type: "Danger",
  //       });
  //     } finally {
  //       this.onHideDeleteModal();
  //     }
  //   } else {
  //     this.onHideDeleteModal();
  //     this.$store.commit("showToastMessage", {
  //       boldText: "Error!",
  //       text: "Invalid id selected",
  //       type: "Danger",
  //     });
  //   }
  // }

  // onHideDeleteModal() {
  //   this.showDeleteModal = false;
  // }

  onStatusChanged(e) {
    this.onUpdateMaintenance(e.target.value, "");
  }

  onTypeChange(e) {
    this.onUpdateMaintenance("", e.target.value);
  }

  idToBeUpdated = "";
  async onUpdateMaintenance(status, type) {
    try {
      let data;
      if (status === "") {
        data = {
          type: type,
        };
      } else if (type === "") {
        data = {
          status: status,
        };
      }
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/${this.idToBeUpdated}`,
        {
          method: "PUT",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      if (response.status === 200) {
        this.$store.commit("showToastMessage", {
          boldText: "Successfully!",
          text: "Maintenance updated",
          type: "Success",
        });
        // this.onReloadData();
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }
}
